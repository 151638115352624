import React from "react";

const Content3 = ({ className, ...rest }) => {
  return (
    <>   
    <div className={className} {...rest}>
      <div style={{maxWidth:"80%"}} className="container position-static">
        <div className="row pt-0 pb-0 pt-lg-0 pb-lg-0 align-items-center justify-content-center position-static">
          {/* Right Image */}
          <div className="col-xl-12 col-lg-12 order-lg-2 order-1">
            {/* content-2 start */}
            <div className="l5-content-gallery-img" data-aos="zoom-in-up" data-aos-once="true" data-aos-delay="750">
              {/* content img start */}
              <div className="d-xs-flex ml-lg-15 mx-xl-n3">
                <div className="d-flex flex-column w-100">
                  {/* single image */}
                  <h1 className="text-center mt-25 text-black">On the Farm</h1>             
                  <h2 className="text-center font-size-6">Click on the animals to find more information</h2>
                  <div className="digma-image  mt-10" data-imageid="2DDF8CE8-CF27-40CE-9632-E5A31174912E"><img className="w-100" alt="Farm Yard Animals" src="https://digmacustomers.blob.core.windows.net/4fe433c9-fb0f-47d4-9f59-37f1909ec6b8/edec4e82-d434-4cf4-a40b-72fea2d58bfe.jpg" /></div>
                  {/* single image end */}
                </div>
              </div>
              {/* abs-content end */}
            </div>
            {/* content-2 end */}
          </div>
          {/* End Right Image */}
        </div>
      </div>
    </div>    
    </>
  );
};

export default Content3;
